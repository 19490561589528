import { initializeApp } from "./firebase-app.js"
import { getAuth, onAuthStateChanged,GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut } from "./firebase-auth.js";

const firebaseConfig = {
    apiKey: "AIzaSyDKvhZNOKpyqSgEffYGPoCnNwltk3ox72M",
    authDomain: "resumai-ca3b8.firebaseapp.com",
    projectId: "resumai-ca3b8",
    storageBucket: "resumai-ca3b8.appspot.com",
    messagingSenderId: "292926657796",
    appId: "1:292926657796:web:f191fcb380890129992c51",
    measurementId: "G-C31P3VM5SC"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider(app);

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const referral = params.referral || null;

document.getElementById("email").focus()
document.getElementById("google_login_button").addEventListener('click',(e) => {
console.log("reached")
signInWithPopup(auth, provider)
  .then((result) => {
    const user = result.user;
    fetch("http://localhost:8080/announce", {
    method: "POST", // or 'PUT'
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({token:user.stsTokenManager.accessToken, referral:referral}),
    })
    .then((response) => {
        // console.log("Success:", data);
    })
    .catch((error) => {
        // console.error("Error:", error);
    });
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...

    // alert(errorMessage);
  });
 });

function sendEmailLogin(email, password){

   signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if ( errorCode == "auth/wrong-password"){
        picoModal("Wrong password. Try again.").show();
      }
      if ( errorCode == "auth/user-not-found"){
        picoModal("User not found. Not a member yet? Register w/ e-mail or sign in with social media to join ResumAI!").show();
      }
      
    });
 }
window.sendEmailLogin = sendEmailLogin
